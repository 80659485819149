import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ content }) => <div className="error text-center">{content}</div>;

Error.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Error;
