import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Loader from '../components/shared/loader';
import Error from '../components/shared/error';
import t from '../locales';
import { getClient, getInitParams } from '../utils/client';
import { setPage } from '../store/app';
import Section from '../components/shared/section';
import Heading from '../components/shared/heading';
import ContentTitle from '../components/shared/content-title';
import { keyPressSpace } from '../helpers';

const Help = ({ setPage, location }) => {
  const [loading, setLoading] = useState(false);
  const [faqItems, setFaqItems] = useState([]);
  const [error, setError] = useState(false);
  const shouldOpenDeleteFaq = location?.search?.includes('how-to-delete-account');
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const HTTPClient = getClient();
      const params = getInitParams('pl');
      HTTPClient.get('faq', { params: { ...params, with_tags: 'helpPage' } })
        .then(({ data }) => {
          setFaqItems(data);
          setLoading(false);
        })
        .catch(() => {
          setError(t('errors.fetchFaq'));
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    setPage('help');
  }, [setPage]);

  return (
    <>
      <Section id="banner" className="bg-black help" inverted>
        <div className="flex" />
      </Section>
      <Heading inverted> {t('title.help')} </Heading>
      <Section>
        <ContentTitle content={t('help.headline')} />
        {error ? (
          <Error content={error} />
        ) : (
          <Faq faqItems={faqItems || []} loading={loading} shouldOpenDeleteFaq={shouldOpenDeleteFaq} />
        )}
      </Section>
    </>
  );
};

export default connect(
  state => ({
    pageName: state.app.pageName,
    langKey: state.app.langKey,
  }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
  })
)(Help);

const Faq = ({ faqItems, loading, shouldOpenDeleteFaq }) => {
  if (loading) return <Loader />;
  if (!Array.isArray(faqItems)) {
    return null;
  }
  return (
    <div className="faq">
      {faqItems.map(faq => {
        const shouldBeOpened = shouldOpenDeleteFaq && faq.id === 17; // 17 - id of delete account faq item
        return <FaqItem key={faq.id} faq={faq} shouldBeOpened={shouldBeOpened} />;
      })}
    </div>
  );
};

const FaqItem = ({ faq, shouldBeOpened }) => {
  const [active, setActive] = useState(shouldBeOpened);
  const thisRef = useRef(null);
  useEffect(() => {
    if (shouldBeOpened && thisRef.current) {
      thisRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [shouldBeOpened]);
  return (
    <div
      className={classnames('faq-item bg-grey-lighter px-6 pt-6 shadow rounded', { active, 'pb-6': active })}
      ref={thisRef}
    >
      <FaqHeader question={faq.question} onClick={() => setActive(!active)} id={faq.id} />
      <FaqContent answear={faq.answer} />
    </div>
  );
};

const FaqHeader = ({ question, onClick }) => {
  return (
    <div
      className="faq-question"
      role="button"
      tabIndex={0}
      onKeyDown={e => keyPressSpace(e, onClick)}
      aria-label="Toggle question"
      onClick={onClick}
    >
      <p className="subtitle"> {question} </p>
      <i className="hm-angle-down teal absolute pin-y pin-r" />
    </div>
  );
};

const FaqContent = ({ answear }) => {
  return (
    <div className="faq-content">
      <p>{answear}</p>
    </div>
  );
};
